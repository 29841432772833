.fontSize {
  &--big {
    font-size: 4.5rem !important;
  }

  &__error {
    font-size: 15rem !important;
    color: #cc0000;
  }

  &__layout {
    color: #fff;
    text-decoration: underline;
    font-weight: 400;
    letter-spacing: 2px !important;
  }
}

.btn {
  &__link {
    color: #ffffff;
    text-decoration: none;
    width: 100%
  }
}

body {
  width: 100%;
  height: auto;
  margin: 0;
  padding-bottom: 13.3rem;
  padding-top: 2rem;

  background-image: url("../../images/static/background.jpg");
  backdrop-filter: blur(16px);
  color: #fff;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}